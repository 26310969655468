import type { Provider } from '@supabase/gotrue-js/src/lib/types';

import { productsProd, productsTest, enterprise, enterpriseDevelop } from './stripeproducts';
import { StripeCheckoutDisplayMode } from '~/lib/stripe/types';
import { plans } from '~/core/stripe/products';

const production = true;

export const appEnv = process.env.NEXT_PUBLIC_APP_ENV;
const emailSmtp = process.env.EMAIL_SMTP_ENDPOINT || 'smtp.postmarkapp.com';  // We use postmark, but some clouds have different providers (SendGrid, etc)
const configuration = {
  appEnv,
  site: {
    name: process.env.NEXT_PUBLIC_SITE_NAME,
    description: `${process.env.NEXT_PUBLIC_BRAND_NAME} is the self-service AI platform for all, no friction, no code, no pain. The ${process.env.NEXT_PUBLIC_BRAND_NAME} AI platform offers speed, autonomy, flexibility and collaboration to build the AI-enabled enterprise.`,
    themeColor: '#ffffff',
    themeColorDark: '#0a0a0a',
    siteUrl: process.env.NEXT_PUBLIC_SITE_URL,
    siteName: process.env.NEXT_PUBLIC_BRAND_NAME || 'Wand',
    twitterHandle: '',
    githubHandle: '',
    language: 'en',
    convertKitFormId: '',
    locale: process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
  },
  app: {
    reviews: 5,
    gptModelId: 'chatgpt35-turbo-0348dljfalskdj',
    gpt4ModelId: 'chatgpt4-mk12dmk123mdsk',
    llmZoo: 'llm-zoo',
    wandyPlain16k: 'wand-plain-16k-e47629030c36d7ce630d07ab5d14b7f7',
    wandyModelId: '5b27ee44e657101e2fe2',
    llama: 'llama2-wrmlwqerlkme',
    zooModels: [] as string[],
    modelIcon: {} as Record<string, string>,
  },
  auth: {
    // ensure this is the same as your Supabase project
    // by default - it's true in production and false in development
    requireEmailConfirmation: process.env.NEXT_PUBLIC_REQUIRE_EMAIL_CONFIRMATION === 'true',
    // NB: Enable the providers below in the Supabase Console
    // in your production project
    providers: {
      emailPassword: true,
      phoneNumber: false,
      emailLink: false,
      oAuth: ['google', 'azure'] as Provider[],
    },
  },
  production,
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  enableThemeSwitcher: true,
  heapAnalytics: 3351020240,
  paths: {
    signIn: '/',
    signUp: '/auth/sign-up',
    signInFromLink: '/auth/link',
    signInMfa: '/auth/verify',
    onboarding: `/onboarding`,
    appHome: '/assistants',
    myApps: '/my-apps',
    savedWandys: '/assistants',
    help: '/help',
    myAssistant: '/my-assistant',
    appPage: '/prebuilt-app/[app]',
    accessDenied: '/access-denied',
    chat: {
      assistants: {
        list: '/assistants',
        create: '/assistants/create',
        edit: '/assistants/[assistant]',
      },
    },
    settings: {
      profile: '/settings/profile',
      organization: '/settings/organization',
      assistantsHome: '/settings/assistants/',
      assistants: '/settings/assistants/my-assistants',
      subscription: '/settings/subscription',
      notifications: '/settings/notifications',
      authentication: '/settings/profile/authentication',
      email: '/settings/profile/email',
      password: '/settings/profile/security',
      help: '/settings/help',
    },
    resetPassword: '/reset-password',
    api: {
      checkout: `/api/stripe/checkout`,
      billingPortal: `/api/stripe/portal`,
      organizations: {
        create: `/api/organizations`,
        current: `/api/organizations/[organization]/current`,
        transferOwnership: `/api/organizations/owner`,
        members: `/api/organizations/members`,
        member: `/api/organizations/members/[member]`,
      },
      apps: {
        list: `/api/prebuilt-apps/list`,
        current: `/api/prebuilt-apps/[app]/current`,
      },
    },
  },
  email: {
    host: emailSmtp,
    port: 587,
    user: process.env.SMTP_USER,
    password: process.env.SMTP_PASSWORD,
    senderAddress: process.env.NEXT_PUBLIC_SENDER_ADDRESS,
    recieverAddress: process.env.NEXT_PUBLIC_RECIEVER_ADDRESS,
  },
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
  stripe: {
    embedded: true,
    displayMode: StripeCheckoutDisplayMode.Overlay,
    products: appEnv === 'develop' ? productsTest : productsProd,
    newproducts: plans,
  },
  emailCommunications: {
    emailSuffix: 'develop' ? '@mywandy.develop.wand.ai' : '@mywandy.develop.wand.ai',
  },
  enterprise: {
    products: appEnv === 'develop' ? enterpriseDevelop : enterprise,
  },
  sources: {
    defaultDemoChurnSourceId: '58e60d2c24c81098',
  },
};

configuration.app.modelIcon = {
  [configuration.app.gpt4ModelId]: 'https://auth-llm.wand.ai/storage/v1/object/public/chatbot_thumbnails/gpt4-mini.svg',
  [configuration.app.gptModelId]: 'https://auth-llm.wand.ai/storage/v1/object/public/chatbot_thumbnails/gpt3-mini.svg',
  ['wandy']: 'https://auth-llm.wand.ai/storage/v1/object/public/chatbot_thumbnails/wand-mini.svg',
  [configuration.app.llama]: 'https://auth-llm.wand.ai/storage/v1/object/public/chatbot_thumbnails/llama-mini.svg',
};

configuration.app.zooModels = [
  configuration.app.gptModelId,
  configuration.app.gpt4ModelId,
  configuration.app.llama,
  configuration.app.llmZoo,
];

export default configuration;
